import React, { Component } from 'react';
import Web3 from 'web3'
import './App.css';
import DonationCenter from '../abis/DonationCenter.json'
import Navbar from './Navbar'
import Main from './Main'
import * as PropTypes from "prop-types";
import {faDiscord, faFacebook, faInstagram, faTelegram, faTwitter, faYoutube} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


FontAwesomeIcon.propTypes = {
  icon: PropTypes.any,
  size: PropTypes.string
};

class App extends Component {

  async componentWillMount() {
    await this.loadWeb3()
    await this.loadBlockchainData()
  }

  async loadWeb3() {
    if (window.ethereum) {
      console.log("1");
      window.web3 = new Web3(window.ethereum)
      // await window.ethereum.enable()
    }
    else if (window.web3) {
      console.log("2");
      window.web3 = new Web3(window.web3.currentProvider)
    }
    else {
      window.alert('Non-Ethereum browser detected. You should consider trying MetaMask!')
    }

    window.ethereum.on('accountsChanged',  accounts => {
        this.setState({account: accounts[0]});
    });
  }

  async updateAccount(acc){
      this.setState({account: acc});
  }
  async loadBlockchainData() {
    const web3 = window.web3
    // Load account
    const accounts = await web3.eth.getAccounts()
    this.setState({ account: accounts[0] })

    const networkId = await web3.eth.net.getId()
    console.log("NETWORK ID"+ networkId);
    const networkData = DonationCenter.networks[networkId]
    if(networkData) {
      const donationCenter = web3.eth.Contract(DonationCenter.abi, networkData.address)
      this.setState({ donationCenter })
      const donationCount = await donationCenter.methods.donationCount().call()
      this.setState({ donationCount })
      // Load products
      for (var i = 1; i <= donationCount; i++) {
        const donation = await donationCenter.methods.donations(i).call()
        this.setState({
          donations: [...this.state.donations, donation]
        })
      }
      let total = await donationCenter.methods.donationTotalAmount().call();
      const donationTotalAmount = web3.utils.fromWei(total.toString(), 'ether');
      this.setState({
        donationTotalAmount1: donationTotalAmount
      });

      this.setState({ loading: false})
      // const donationTotal = await donationCenter.methods.donationTotalAmount().call()
    } else {
      window.alert('Marketplace contract not deployed to detected network.')
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      account: '',
      donationCount: 0,
      donationTotalAmount1:0,
      donations: [],
      loading: true
    }
    this.updateAccount = this.updateAccount.bind(this);
    this.createDonation = this.createDonation.bind(this);
  }

  createDonation(name, message, amount) {
    this.setState({ loading: true })
    this.state.donationCenter.methods.createDonation(name, message, amount).send({ from: this.state.account, value:amount })
    .once('receipt', (receipt) => {
      this.setState({ loading: false })
    })
  }

  getTotalDonation(){
    return this.state.getTotalDonation;
  }
  render() {

    return (
      <div>
        <Navbar account={this.state.account} updateAccount={this.updateAccount} />
        <div className="container-fluid mt-5">
          <div className="row">
            <main role="main" className="col-lg-12 d-flex">
              { this.state.loading
                ? <div id="loader" className="text-center"><p className="text-center">Loading...</p></div>
                : <Main
                      donations={this.state.donations}
                      totalDonationAmount = {this.state.donationTotalAmount1}
                      createDonation={this.createDonation}
                  />
              }
            </main>
          </div>
          <div className="social-container">
            <h3>Social Follow</h3>
            <a href="https://twitter.com/ethereum_beggar"
               className="instagram social">
              <FontAwesomeIcon icon={faTwitter} size="2x"/>
            </a>
            <a href="https://discord.gg/Y5pSWtvnwz"
               className="instagram social">
              <FontAwesomeIcon icon={faDiscord} size="2x"/>
            </a>
            <a href="https://t.me/ethbeggar"
               className="instagram social">
              <FontAwesomeIcon icon={faTelegram} size="2x"/>
            </a>
          </div>
        </div>

      </div>
    );
  }
}

export default App;
