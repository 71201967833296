import React, { Component } from 'react';

import './App.css';
import {Button} from "react-bootstrap";

class Navbar extends Component {


    constructor(props) {
        super(props)
        console.log(props)
        this.handleConnectWallet = this.handleConnectWallet.bind(this);

    }




    async handleConnectWallet() {
        await this.ConnectWallet()
    }

    async ConnectWallet(){

        const web3 = window.web3;
        const accounts = await window.ethereum.request({method: 'eth_requestAccounts'});
        this.props.updateAccount(accounts[0]);

    }


    render() {
        const isConnected = this.props.account != '' && this.props.account !=null && this.props.account != undefined;
        let button = isConnected ? <small className="text-black-50"><span id="account">{this.props.account}</span></small> : <button onClick={this.handleConnectWallet}>
            Connect Wallet
        </button>;
        return (
            <nav className="navbar navbar-dark fixed-top  flex-md-nowrap p-0 shadow">
                <a
                    className="navbar-brand col-sm-3 col-md-2 mr-0"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Crypto Begging
                </a>
                <ul className="navbar-nav px-3">
                    <li className="nav-item text-nowrap d-none d-sm-none d-sm-block">
                        <div>{button}</div>
                    </li>
                </ul>
            </nav>
        );
    }
}

export default Navbar;
