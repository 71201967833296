import React, {Component} from 'react';
// import DatatablePage from './table';
import {MDBDataTable, MDBDataTableV5, MDBTable, MDBTableBody, MDBTableHead} from 'mdbreact';
function Item(id, speaker, country) {
    this.id = id;
    this.speaker = speaker;
    this.country = country;
}
class Main extends Component {

    getDonationData() {
        const r = this.props.donations.map((donations, key)=>{
            return {
                id : parseInt(donations.id.toString()),
                name : donations.name,
                msg : donations.msg,
                amount : parseFloat(window.web3.utils.fromWei(donations.amount.toString(), 'Ether')),
                owner : donations.owner
            }
        });

        return {
            columns: [
                {
                    label: '#',
                    field: 'id',
                    sort: 'asc',
                    // width: 70,
                    // attributes: {
                    //     'aria-controls': 'DataTable',
                    //     'aria-label': 'Name',
                    // },
                    minimal: 'sm'
                },
                {
                    label: 'Name',
                    field: 'name',
                    sort: 'asc',
                    minimal: 'sm'
                    // width: 320
                },
                {
                    label: 'Message',
                    field: 'msg',
                    sort: 'asc',
                    minimal: 'sm'
                    // width: 1000
                },
                {
                    label: 'Amount',
                    field: 'amount',
                    sort: 'asc',
                    minimal: 'sm'
                    // width: 100
                },
                {
                    label: 'Donator',
                    field: 'owner',
                    sort: 'asc',
                    minimal: 'sm'
                    // width: 400
                }
            ],
            rows: r


        }
    }

    render() {
        return (
            <div id="content" className="row">
                <div className="column">
                    <h1>Total Tip Balance:</h1>
                    <h1>{this.props.totalDonationAmount} ETH</h1>
                    <h2>Give Tips</h2>
                    <form onSubmit={(event) => {
                        event.preventDefault()
                        const name = this.donationName.value
                        const msg = this.donationMsg.value
                        const amount = window.web3.utils.toWei(this.amount.value.toString(), 'Ether')
                        console.log(amount)

                        this.props.createDonation(name, msg, amount)
                    }}>
                        <div className="form-group mr-sm-2">
                            <input
                                id="donationName"
                                type="text"
                                ref={(input) => {
                                    this.donationName = input
                                }}
                                className="form-control"
                                placeholder="Tipper's Name (max char 12)"
                                maxLength={12}
                                required/>
                        </div>
                        <div className="form-group mr-sm-2">
                            <input
                                id="donationMsg"
                                type="text"
                                ref={(input) => {
                                    this.donationMsg = input
                                }}
                                className="form-control"
                                placeholder="Message to leave (max char 60)"
                                maxLength={60}
                                required/>
                        </div>
                        <div className="form-group mr-sm-2">
                            <input
                                id="donationAmount"
                                type="text"
                                ref={(input) => {
                                    this.amount = input
                                }}
                                className="form-control"
                                placeholder="Tip amount"
                                required/>
                        </div>
                        <button type="submit" className="btn btn-primary">Tip</button>
                    </form>
                    <p>&nbsp;</p>
                    <h2>Tippers List</h2>
                    <MDBTable
                        scrollY
                        maxHeight="30vh"
                        responsive={true}
                        striped
                        bordered
                        autoWidth>
                        <MDBTableHead columns={this.getDonationData().columns}/>
                        <MDBTableBody rows={this.getDonationData().rows}/>
                    </MDBTable>
                </div>
                <div className="column">
                    <div className="multiline">
                        <h1>RoadMap</h1>
                        <p className='new-line'>{
                            "1. Make this website prettier and functional.\n2. Implement Hand Drawn Drawing NFT airdrop to tippers.\n3. Airdrop Hand Drawn Drawing NFT to first 100 tippers.\n4. Monthly Unique Drawing NFT Airdrop to top 10 tippers.\n5. Implement Governance Token for my tippers\n"
                        }</p>
                    </div>
                    <div className="multiline">
                        <h1>About Me</h1>
                        <p className='new-line'>{
                            "Hello Everyone,\nI'm just a little guy who has decided to become a cyber beggar!\nHoping people in the crypto world to tip me.\nI'm planning to expand this little experimental project through out my roadmap :)\nI beg you to tip me any amount of ethereum...\nplease help me out."
                            }
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default Main;
